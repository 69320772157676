/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
const n = /^-?(\d+(\.\d+)?)\s*((px)|(pt))?$/i,
  t = "screenUtils.toPt: input not recognized!",
  r = 96;
function u(n) {
  return n ? n / 72 * r : 0;
}
function e(n) {
  return n ? 72 * n / r : 0;
}
function o(r) {
  if ("string" == typeof r) {
    const u = r.match(n);
    if (u) {
      const n = Number(u[1]),
        t = u[3] && u[3].toLowerCase(),
        o = "-" === r.charAt(0),
        c = "px" === t ? e(n) : n;
      return o ? -c : c;
    }
    return console.warn(t), null;
  }
  return r;
}
function c(n = 0, t = 0) {
  return {
    x: n,
    y: t
  };
}
function i(n = 0, t = 0) {
  return [n, t];
}
function f(n = 0, t = 0) {
  return {
    x: n,
    y: t
  };
}
function s(n = 0, t = 0) {
  return y([n, t]);
}
function x(n = 0, t = 0, r = 0) {
  return p([n, t, r]);
}
function y(n) {
  return n;
}
function p(n) {
  return n;
}
function l(n) {
  return n;
}
function a(n, t) {
  return t ? (t.x = n[0], t.y = n[1], t) : {
    x: n[0],
    y: n[1]
  };
}
function d(n, t) {
  return t ? (t[0] = n.x, t[1] = n.y, t.length > 2 && (t[2] = 0), t) : [n.x, n.y];
}
export { y as castRenderScreenPointArray, p as castRenderScreenPointArray3, l as castScreenPointArray, f as createRenderScreenPoint, s as createRenderScreenPointArray, x as createRenderScreenPointArray3, c as createScreenPoint, i as createScreenPointArray, u as pt2px, e as px2pt, a as screenPointArrayToObject, d as screenPointObjectToArray, o as toPt };